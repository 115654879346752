import { ButtonProps } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { useSearchConfigurationContext } from '@Components/SearchForm/SearchConfigurationContext';
import { useSearchResultsPageUrl } from '@Components/SearchForm/useSearchResultsPageUrl';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useSearchSelectionStore } from '@Stores/StoreContext';

interface SearchButtonProps extends ClassNameProps {}

export const SearchButton: React.FC<SearchButtonProps> = ({ className }) => {
  const { t } = useTranslation();
  const selection = useSearchSelectionStore((state) => state);
  const { useClientSideNavigation } = useSearchConfigurationContext();
  const getSearchResultsPageUrl = useSearchResultsPageUrl();

  const commonProps: { [Key in keyof ButtonProps]: ButtonProps[Key] } = {
    className,
    'data-id': 'dp-search-cta',
    variant: 'Primary',
    size: '48',
    asAnchor: false,
    internal: useClientSideNavigation,
    href: getSearchResultsPageUrl({
      type: 'DPSRP',
      selection,
    }),
  };

  return (
    <Fragment>
      <Button
        stretch
        onClick={() => {
          trackEventClick('search-ui-dp-search');
        }}
        {...commonProps}
      >
        {t('search')}
      </Button>
    </Fragment>
  );
};
