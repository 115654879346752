import { ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback } from 'react';

import { FlexibilitySelector } from './FlexibilitySelector';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { SearchInput } from '../SearchInput/SearchInput';
import { ClassNameProps } from '@ComponentProps';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { Popover } from '@UX/components/Search/Popover';

export interface FlexibilityInputProps extends Omit<ClassNameProps, 'as'> {
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
}

export const FlexibilityInput: React.FC<FlexibilityInputProps> = ({
  hasInputTitle,
  className,
  showInputTitleOnMobile,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen, setModalClose] = useModal();
  const selectedFlexibility = useSearchSelectionStore((state) => state.flexibility);

  const trigger = (
    <SearchInput
      data-id="flexibility-input"
      prefixIconName="Content/InputDate"
      {...(hasInputTitle && {
        title: t('searchUi.flexibilityInput.inputLabel'),
      })}
      showTitleOnMobile={showInputTitleOnMobile}
      placeholder={t('newFlexibility.flex_0')}
      label={t('newFlexibility.flex', { context: String(selectedFlexibility) })}
      onClick={useCallback(() => {
        setModalOpen();
        trackEventClick('search-ui-flexibility-input');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])}
    />
  );

  const content = <FlexibilitySelector onChange={setModalClose} />;

  return (
    <div className={className}>
      <PopoverOrModal
        isOpen={isModalOpen}
        popover={
          <Popover
            size="Medium"
            isOpen={isModalOpen}
            anchorPosition="top-left"
            trigger={trigger}
            onClickOutside={setModalClose}
            contentStyle={{
              padding: 0,
            }}
          >
            {content}
          </Popover>
        }
        modal={
          <Fragment>
            {trigger}
            <LoadableModal
              show
              data-id="modal-container"
              onClose={setModalClose}
              mobileFloating
              Header={
                <ModalHeader
                  label={t('searchUi.flexibilityInput.selectorTitle')}
                  layout="medium"
                  onClose={setModalClose}
                />
              }
              Content={content}
            />
          </Fragment>
        }
      />
    </div>
  );
};
