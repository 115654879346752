import { ContentSwitcherHeader, Tabs } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { DaySelector } from './DaySelector';
import { MonthSelector } from './MonthSelector';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export enum DateSelectorTabIds {
  monthCalendar = 'month-calendar',
  dayCalendar = 'day-calendar',
}

interface DateSelectorProps {}

export const DateSelector: React.FC<DateSelectorProps> = () => {
  const { t } = useTranslation();
  const isMonthSelected = useSearchSelectionStore((state) => state.isMonthSelected());

  return (
    <Tabs
      initialTabId={
        isMonthSelected ? DateSelectorTabIds.monthCalendar : DateSelectorTabIds.dayCalendar
      }
      prefix="search-form-calendar"
      tabListStyles={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4xs',
        borderRadius: 'rounded',
        backgroundColor: 'backgroundLightsubtle',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
      tabPanelStyles={{
        paddingTop: ['l', '2xs'],
      }}
    >
      <Tabs.Tab
        lazy
        id="day-calendar"
        header={<ContentSwitcherHeader label={t('specificDate')} />}
      >
        <DaySelector />
      </Tabs.Tab>

      <Tabs.Tab
        lazy
        id="month-calendar"
        header={<ContentSwitcherHeader label={t('wholeMonth')} />}
      >
        <MonthSelector />
      </Tabs.Tab>
    </Tabs>
  );
};
