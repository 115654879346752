import React, { Fragment } from 'react';

import { useSearchConfigurationContext } from './SearchConfigurationContext';
import { ClassNameProps } from '@ComponentProps';
import { DynamicPackageSearchContextProvider } from '@Contexts/SearchTypeContext/SearchTypeContext';
import { DateInput } from '@DesignSystemComponents/Search/DateInput/DateInput';
import { FlexibilityInput } from '@DesignSystemComponents/Search/FlexibilityInput/FlexibilityInput';
import { GuestInput } from '@DesignSystemComponents/Search/GuestInput/GuestInput';
import { NightsInput } from '@DesignSystemComponents/Search/NightsInput/NightsInput';
import { SearchButton } from '@DesignSystemComponents/Search/SearchButton/SearchButton';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { DepartureInput } from '@UX/components/Search/DepartureInput/DepartureInput';
import { DestinationInput } from '@UX/components/Search/DestinationInput/DestinationInput';

function getDepartureGridColumn({
  showDestinationInput,
  showSearchButton,
}: {
  showDestinationInput: boolean;
  showSearchButton: boolean;
}) {
  if (!showDestinationInput) {
    return 'span 24';
  }

  if (showSearchButton) {
    return 'span 15';
  }

  return 'span 18';
}

function getFlexibilityGridColumnStart({
  showDestinationInput,
  showSearchButton,
}: {
  showDestinationInput: boolean;
  showSearchButton: boolean;
}) {
  if (!showDestinationInput) {
    return 25;
  }

  if (showSearchButton) {
    return 31;
  }

  return 37;
}

function getFlexibilityGridColumn({
  showDestinationInput,
  showSearchButton,
}: {
  showDestinationInput: boolean;
  showSearchButton: boolean;
}) {
  return `${getFlexibilityGridColumnStart({ showDestinationInput, showSearchButton })}  / span 11`;
}

type NewDynamicPackageSearchFormProps = ClassNameProps & {
  isLoose?: boolean;
};

export const NewDynamicPackageSearchForm: React.FC<NewDynamicPackageSearchFormProps> = ({
  className,
  isLoose,
}) => {
  const { showSearchButton, isFlexibilityEnabled, showDestinationInput } =
    useSearchConfigurationContext();

  const [isSpecificDateSelected] = useSearchSelectionStore((state) => [
    state.isSpecificDateSelected(),
  ]);

  const showFlexibilityInput = isFlexibilityEnabled && isSpecificDateSelected;

  return (
    <DynamicPackageSearchContextProvider>
      <article className={className}>
        <section
          sx={{
            display: 'grid',
            // Divides the row in upto 26 columns (desktop) to allow elements in the search form
            // to have the right width
            gridTemplateColumns: [
              'repeat(1, minmax(0, 1fr))',
              'repeat(2, minmax(0, 1fr))',
              `repeat(64, 1fr)`,
            ],
            gridTemplateRows: [null, null, `repeat(${showFlexibilityInput ? 2 : 1}, 1fr)`],
            rowGap: [isLoose ? 'xs' : '3xs', 'xs', '3xs'],
            columnGap: [null, '3xs'],
          }}
        >
          {showDestinationInput && (
            <DestinationInput
              hasInputTitle
              showInputTitleOnMobile={isLoose}
              allowTagsRemoval
              sx={{
                gridColumn: [null, null, showSearchButton ? 'span 15' : 'span 18'],
                width: [null, null, '100%'],
              }}
            />
          )}
          <DepartureInput
            hasInputTitle
            showInputTitleOnMobile={isLoose}
            allowTagsRemoval
            sx={{
              gridColumn: [
                null,
                showDestinationInput ? 'span 1' : 'span 2',
                getDepartureGridColumn({ showDestinationInput, showSearchButton }),
              ],
              width: [null, null, '100%'],
            }}
          />
          <DateInput
            hasInputTitle
            showInputTitleOnMobile={isLoose}
            sx={{
              gridColumn: [null, 1, 'span 11'],
            }}
          />
          {showFlexibilityInput && (
            <Fragment>
              <FlexibilityInput
                hasInputTitle
                showInputTitleOnMobile={isLoose}
                sx={{
                  gridColumn: [
                    null,
                    null,
                    getFlexibilityGridColumn({ showDestinationInput, showSearchButton }),
                  ],
                  gridRow: [null, 3, 2],
                }}
              />
              {showSearchButton && (
                <div
                  sx={{
                    display: ['none', 'block', 'none'],
                    gridRow: [null, 3, null],
                  }}
                />
              )}
            </Fragment>
          )}
          <NightsInput
            hasInputTitle
            showInputTitleOnMobile={isLoose}
            sx={{ gridColumn: [null, null, showDestinationInput ? `span 7` : `span 10`] }}
          />
          <GuestInput
            hasInputTitle
            showInputTitleOnMobile={isLoose}
            sx={{ gridColumn: [null, null, showDestinationInput ? 'span 10' : 'span 11'] }}
          />
          {showSearchButton && (
            <SearchButton
              sx={{
                alignSelf: 'end',
                marginTop: ['4xs', null, 0],
                gridColumn: [null, null, showDestinationInput ? 'span 6' : 'span 8'],
              }}
            />
          )}
        </section>
      </article>
    </DynamicPackageSearchContextProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default NewDynamicPackageSearchForm;
