import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'urql';

import { AccommodationSuggestion, DestinationSuggestion, Query, Suggestion } from '@AuroraTypes';
import { useUpdateEffect } from '@Core/hooks/useUpdateEffect';
import { isQueryResolved } from '@Core/isQueryResolved';
import { DestinationAutocompleteTrackingMethod } from '@Core/tracking/hooks/useDestinationAutocompleteTracking';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';
import getDestinations from '@UX/components/Search/DestinationInput/getDestinations.gql';
import { Option } from '@UX/components/Search/types';

type UseDestinationLookupProps = {
  searchTerm: string;
  pauseLookup: boolean;
  destinationInputChangedTracking: DestinationAutocompleteTrackingMethod;
  destinationOptionSelectedTracking: DestinationAutocompleteTrackingMethod;
  destinationOptionsLoadedTracking: DestinationAutocompleteTrackingMethod;
};

export type UseDestinationLookUpResult = {
  destinationOptions: Suggestion[];
  selectedDestinationsOptions: Option[];
  lookupResults: Option[];
  isLookupLoading: boolean;
};

export const useDestinationLookup = ({
  searchTerm,
  pauseLookup,
  destinationInputChangedTracking,
  destinationOptionSelectedTracking,
  destinationOptionsLoadedTracking,
}: UseDestinationLookupProps): UseDestinationLookUpResult => {
  // state
  const previousSearchTerm = useRef(searchTerm);
  const [lastValidLookUpResults, setLastValidLookUpResults] = useState<Option[]>([]);

  // search store state
  const [destinationIds, searchSelection] = useSearchSelectionStore((state) => [
    state.destinationIds,
    {
      boardBasis: state.boardBasis,
      cancellationPolicy: state.cancellationPolicy,
      date: state.date,
      departureAirports: state.departureAirports,
      flexibility: state.flexibility,
      nights: state.nights,
      rooms: state.rooms,
      filters: state.filters,
      source: state.source,
    },
  ]);

  const selectedDestinationsOptions = useSearchAvailabilityStore(({ destinations }) => {
    const mappedDestinations = destinations.map(
      ({ id: value, name: label }) => ({ value, label }) as Option,
    );

    const knownDestinations = [...lastValidLookUpResults, ...mappedDestinations];

    return destinationIds
      .map((id) => knownDestinations.find(({ value }) => value === id))
      .filter(Boolean) as Option[];
  });

  // query
  const [{ data, fetching }] = useQuery<Query>({
    query: getDestinations,
    pause: pauseLookup,
    variables: {
      query: searchTerm,
      searchSelection,
      includeImages: !searchTerm,
    },
  });

  // query response processing
  const isLookupQueryLoading = !isQueryResolved(fetching, data);

  const currentLookupResultsSnapshot = useMemo(() => {
    if (isLookupQueryLoading || !data.Lookup.destinations) {
      return [];
    }

    return data.Lookup.destinations
      .map((item) => {
        // treat the suggestion as either
        const suggestion = item as DestinationSuggestion & AccommodationSuggestion;

        const suggestionBase = {
          value: suggestion.id,
          icon: suggestion.icon,
          image: suggestion.image,
          selected: destinationIds.includes(suggestion.id),
        };

        if (suggestion.accommodation) {
          return {
            ...suggestionBase,
            type: 'accomodation',
            available: true,
            label: suggestion.accommodation.name,
            secondaryLabel: suggestion.accommodation.locationLabel,
          };
        } else if (suggestion.destination) {
          return {
            ...suggestionBase,
            type: 'destination',
            available: suggestion.available,
            label: suggestion.destination.name,
            secondaryLabel: suggestion.destination.locationLabel,
          };
        } else {
          return null;
        }
      })
      .filter(Boolean) as Option[];
  }, [data, isLookupQueryLoading, destinationIds]);

  useEffect(() => {
    if (currentLookupResultsSnapshot.length > 0) {
      setLastValidLookUpResults(currentLookupResultsSnapshot);
    }
  }, [currentLookupResultsSnapshot]);

  // auto complete tracking
  // update prev look up results
  const autocompleteEventPayload = {
    searchTerm,
    options: currentLookupResultsSnapshot,
    selectedOptions: selectedDestinationsOptions,
    isOptionsLoaded: !isLookupQueryLoading,
  };

  // Track when search term changes
  if (searchTerm !== previousSearchTerm.current) {
    previousSearchTerm.current = searchTerm;
    destinationInputChangedTracking({
      ...autocompleteEventPayload,
      isListOpened: true,
    });
  }

  // track when data is loaded
  useEffect(() => {
    if (!isLookupQueryLoading) {
      destinationOptionsLoadedTracking({
        ...autocompleteEventPayload,
        isListOpened: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLookupQueryLoading]);

  // track when destinations have changed
  useUpdateEffect(() => {
    destinationOptionSelectedTracking({
      ...autocompleteEventPayload,
      isListOpened: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationIds]);

  // output
  return {
    isLookupLoading: isLookupQueryLoading,
    lookupResults: currentLookupResultsSnapshot,
    destinationOptions: data?.Lookup.destinations ?? [],
    selectedDestinationsOptions,
  };
};
