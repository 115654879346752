import { Label, mediaBrick, keyboardOutline } from '@loveholidays/design-system';
import { Dayjs } from 'dayjs';
import React, { useState } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { useDate } from '@Dates/useDateHook';
import { MonthPrices } from '@Stores/SearchAvailabilityStore';
import { useSearchSelectionStore } from '@Stores/StoreContext';

interface DayGridProps extends ClassNameProps {
  yearMonth: string;
  monthPrices: MonthPrices;
}

const isInDateRange = (date: Dayjs | null, startDate?: Dayjs, endDate?: Dayjs) => {
  if (!date || !startDate || !endDate) {
    return false;
  }

  return date.isAfter(startDate) && date.isSameOrBefore(endDate);
};

export const DayGrid: React.FC<DayGridProps> = ({ monthPrices, yearMonth }) => {
  const [date, setDateOnly, nights, isMonthSelected] = useSearchSelectionStore((state) => [
    state.date,
    state.setDateOnly,
    state.nights,
    state.isMonthSelected(),
  ]);

  const { getDayGridInWeeks, getWeekdaysMin } = useDate();
  const [weekdays] = useState(() => getWeekdaysMin());
  const [dayGridInWeeks] = useState(() => getDayGridInWeeks(yearMonth));

  const endDate = date ? date.add(nights, 'day') : undefined;

  const emptyDays = dayGridInWeeks[0].findIndex(Boolean);

  return (
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, minmax(44px, 1fr))',
        gridTemplateRows: `repeat(${dayGridInWeeks.length + 1}, minmax(44px, 1fr))`,
        alignItems: 'center',
        justifyItems: 'center',
        ...mediaBrick({
          gridTemplateColumns: 'repeat(7, minmax(38px, 1fr))',
        }),
      }}
    >
      {weekdays.map((d, index) => (
        <Label
          key={index}
          variant="smallbold"
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {d}
        </Label>
      ))}
      {dayGridInWeeks.map((weeks, weekIndex) =>
        weeks.map((day, dayIndex) => {
          if (!day) {
            return <div key={dayIndex} />;
          }

          const isSelected = day !== null && Boolean(date?.isSame(day)) && !isMonthSelected;

          // is the day within the range of selected date + number of nights
          const isInRange = isInDateRange(day, date, endDate) && !isMonthSelected;

          const adjustedDayIndex = dayIndex + weekIndex * 7 - emptyDays;
          const isDisabled = !monthPrices || !monthPrices[adjustedDayIndex];

          return (
            <button
              data-id="day"
              disabled={isDisabled}
              type="button"
              key={dayIndex}
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: '8',
                '&:hover': {
                  ...(!isDisabled &&
                    !isSelected && {
                      backgroundColor: 'tagprimaryDefault',
                      color: 'textDefault',
                    }),
                },
                ...(isSelected && {
                  backgroundColor: 'backgroundBlack',
                  color: 'textLight',
                }),
                ...(isInRange && {
                  backgroundColor: 'backgroundLightsubtle',
                  borderRadius: 0,
                }),
                ...(isDisabled && {
                  color: 'textDisabled',
                }),
                ...keyboardOutline,
              }}
              onClick={() => {
                setDateOnly(day);
              }}
            >
              {day?.format('D')}
            </button>
          );
        }),
      )}
    </div>
  );
};
