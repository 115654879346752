import { Label } from '@loveholidays/design-system';
import React, { useCallback, useState, useEffect } from 'react';

import { DayGrid } from './DayGrid';
import { MonthSelect } from '@Components/Calendar/MonthSelect/MonthSelect';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';
import { useDate } from '@Dates/useDateHook';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export const DaySelector: React.FC = () => {
  const { formatMonthYear } = useDate();
  const [date] = useSearchSelectionStore((state) => [state.date]);

  const [calendar] = useSearchAvailabilityStore((state) => [state.calendar]);

  const getSelectedYearMonth = useCallback(() => {
    if (date) {
      return date.format('YYYY-MM');
    }

    const firstMonthWithAvailabilityEntry = Object.entries(calendar).find(([, monthPrices]) =>
      monthPrices.some((p) => p !== null),
    );

    if (firstMonthWithAvailabilityEntry) {
      return firstMonthWithAvailabilityEntry[0];
    }

    return Object.keys(calendar)[0];
  }, [date, calendar]);

  const [selectedYearMonth, setSelectedYearMonth] = useState(getSelectedYearMonth());

  if (!selectedYearMonth) {
    return null;
  }

  const availability = Object.entries(calendar).reduce(
    (acc: Record<string, boolean>, [yearMonth, prices]) => {
      acc[yearMonth] = prices.length !== 0;

      return acc;
    },
    {},
  );

  const handleMonthChange = (month: string) => {
    setSelectedYearMonth(month);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!date) {
      return;
    }

    const el = document.querySelector(`[data-id=day-calendar-${date.format('YYYY-MM')}]`);

    if (el) {
      setTimeout(() => {
        el.scrollIntoView({
          behavior: getScrollBehavior(),
          block: 'nearest',
          inline: 'start',
        });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        sx={{
          marginBottom: 's',
          paddingTop: 's',
          display: ['none', 'block'],
        }}
      >
        <MonthSelect
          availability={availability}
          selectedYearMonth={selectedYearMonth}
          onChange={handleMonthChange}
        />
      </div>
      {Object.entries(calendar).map(([yearMonth, monthPrices]) => {
        const formattedMonthYear = formatMonthYear(yearMonth);

        return (
          <div
            key={yearMonth}
            sx={{
              display: ['grid', selectedYearMonth === yearMonth ? 'grid' : 'none'],
              marginBottom: ['xs', 's'],
            }}
            data-id={`day-calendar-${yearMonth}`}
          >
            <Label
              variant="largebold"
              sx={{
                display: ['block', 'none'],
                marginY: 's',
                justifySelf: 'center',
                alignSelf: 'center',
              }}
            >
              {formattedMonthYear}
            </Label>
            <DayGrid
              yearMonth={yearMonth}
              monthPrices={monthPrices}
            />
          </div>
        );
      })}
    </div>
  );
};
