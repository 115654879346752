import { useCallback, useMemo } from 'react';

import { sendEvent } from '../sendEvent';
import { AutocompleteEventType, TrackingEvent } from '../types';
import { useSearchTypeContext } from '@Contexts/SearchTypeContext/SearchTypeContext';
import { Option } from '@UX/Forms/MultiSelect/MultiSelect';

export type DestinationAutocompleteTrackingParams = {
  searchTerm: string;
  options: Option[];
  selectedOptions: Option[];
  isOptionsLoaded: boolean;
  isListOpened: boolean;
};

export type DestinationAutocompleteTrackingMethod = (
  params: DestinationAutocompleteTrackingParams,
) => void;

export const useDestinationAutocompleteTracking = (
  componentOrigin: string,
  types: AutocompleteEventType[],
): DestinationAutocompleteTrackingMethod[] => {
  const { origin } = useSearchTypeContext();

  const trackingCallback = useCallback(
    (type: AutocompleteEventType) =>
      ({
        searchTerm,
        options,
        selectedOptions,
        isOptionsLoaded,
        isListOpened,
      }: DestinationAutocompleteTrackingParams) => {
        const { optionValues, preparedOptions } = options.reduce(
          (acc, { value, label, available }) => {
            acc.optionValues.push(value);
            acc.preparedOptions.push({ value, label, available });

            return acc;
          },
          {
            optionValues: [] as string[],
            preparedOptions: [] as Pick<Option, 'value' | 'label' | 'available'>[],
          },
        );

        sendEvent({
          event: TrackingEvent.destinationAutocomplete,
          origin,
          componentOrigin,
          type,
          searchTerm,
          isOptionsLoaded,
          isListOpened,
          options: preparedOptions,
          selectedOptions: selectedOptions.map(({ value, label }) => ({
            label,
            value,
            index: optionValues.indexOf(value),
          })),
        });
      },
    [origin, componentOrigin],
  );

  return useMemo(() => types.map(trackingCallback), [types, trackingCallback]);
};
