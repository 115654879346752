import { Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, ReactElement } from 'react';

type DateInputContentProps = {
  content: ReactElement;
};

export const DateInputPopoverContent: React.FC<DateInputContentProps> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <section
        sx={{
          marginX: '-l',
          padding: 'xs',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '2xl',
        }}
      >
        <Label
          variant="mediumbold"
          sx={{ display: 'block' }}
        >
          {t('whenAreYouLeaving')}
        </Label>
      </section>
      <section>{content}</section>
    </Fragment>
  );
};
