import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, useRef } from 'react';

import { SearchInput } from '../SearchInput/SearchInput';
import { useFakeAutoFocusTrigger } from '../useFakeAutoFocusTrigger';
import { ClassNameProps } from '@ComponentProps';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useSearchAvailabilityStore } from '@Stores/StoreContext';

export interface SearchInputTriggerProps extends ClassNameProps {
  onOpen: () => void;
  onRemoveTag: (value: string) => void;
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
  allowTagsRemoval?: boolean;
}

export const SearchInputTrigger: React.FC<SearchInputTriggerProps> = ({
  onOpen,
  onRemoveTag,
  className,
  hasInputTitle,
  showInputTitleOnMobile,
  allowTagsRemoval,
}) => {
  const { t } = useTranslation();

  const tags = useSearchAvailabilityStore((state) =>
    state.destinations.map(({ id, name }) => ({ label: name, value: id })),
  );

  const inputRef = useRef<HTMLDivElement>(null);

  const triggerFakeInputFocus = useFakeAutoFocusTrigger(inputRef);

  const handleOpen = useCallback(() => {
    onOpen();
    triggerFakeInputFocus();
    trackEventClick('search-ui-destination-input');
  }, [onOpen, triggerFakeInputFocus]);

  const areTagsProvided = tags && tags.length;

  return (
    <SearchInput
      data-id="destination-input"
      ref={inputRef}
      className={className}
      placeholder={t('searchUi.destinationInput.anyDestinationOrHotel')}
      onClick={handleOpen}
      showTitleOnMobile={showInputTitleOnMobile}
      tags={tags}
      removeTag={allowTagsRemoval ? onRemoveTag : undefined}
      {...(hasInputTitle && {
        title: t('facets.destinations'),
      })}
      {...(areTagsProvided && !hasInputTitle
        ? {
            prefix: t('to'),
          }
        : {
            prefixIconName: 'Content/Place',
          })}
    />
  );
};
