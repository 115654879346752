import { ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, Fragment, useEffect } from 'react';

import { DateInputPopoverContent } from './DateInputPopoverContent';
import { DateSelector } from './DateSelector';
import { Popover } from '../Popover/Popover';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { SearchInput } from '../SearchInput/SearchInput';
import { ClassNameProps } from '@ComponentProps';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useDate } from '@Dates/useDateHook';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export interface DateInputProps extends ClassNameProps {
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
}

export const DateInput: React.FC<DateInputProps> = ({
  className,
  hasInputTitle,
  showInputTitleOnMobile,
}) => {
  const { t } = useTranslation();
  const { formatCalendarDate } = useDate();

  const [date, setDate, isMonthSelected] = useSearchSelectionStore((state) => [
    state.date,
    state.setDate,
    state.isMonthSelected(),
  ]);

  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const getLabel = () => {
    if (!date) {
      return '';
    }

    return isMonthSelected ? date.format('MMM YYYY') : formatCalendarDate(date);
  };

  useEffect(() => {
    if (isModalOpen) {
      setModalClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const trigger = (
    <SearchInput
      data-id="date-input"
      prefixIconName="Content/InputDate"
      placeholder={t('anyDate')}
      label={getLabel()}
      onClick={useCallback(() => {
        setModalOpen();
        trackEventClick('search-ui-date-input');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])}
      {...(hasInputTitle && {
        title: t('facets.date'),
      })}
      showTitleOnMobile={showInputTitleOnMobile}
      onClear={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setDate(undefined, undefined);
      }}
    />
  );

  const content = <DateSelector />;

  return (
    <div className={className}>
      <PopoverOrModal
        isOpen={isModalOpen}
        popover={
          <Popover
            className={className}
            size="Medium"
            isOpen={isModalOpen}
            anchorPosition={[undefined, 'top-left', 'top-left']}
            trigger={trigger}
            onClickOutside={() => setModalClose()}
            contentStyle={{
              paddingTop: 0,
              paddingBottom: 'l',
            }}
          >
            <DateInputPopoverContent content={content} />
          </Popover>
        }
        modal={
          <Fragment>
            {trigger}
            <LoadableModal
              show={isModalOpen}
              data-id="modal-container"
              onClose={setModalClose}
              Header={
                <ModalHeader
                  label={t('whenAreYouLeaving')}
                  layout="medium"
                  onClose={setModalClose}
                />
              }
              Content={content}
            />
          </Fragment>
        }
      />
    </div>
  );
};
