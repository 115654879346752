import { Label, TriggerButton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export interface Option {
  label: string;
  value: string | number;
  selected?: boolean;
}

interface FlexibilitySelectorProps {
  onChange: () => void;
}

export const FlexibilitySelector: React.FC<FlexibilitySelectorProps> = ({ onChange }) => {
  const { t } = useTranslation();

  const [selectedFlexibility, setSelectedFlexibility] = useSearchSelectionStore((state) => [
    state.flexibility,
    state.setFlexibility,
  ]);

  const availableFlexibility = useSearchAvailabilityStore((state) => state.flexibility);

  const options = availableFlexibility.map((flexibility) => ({
    value: flexibility,
    label: t('newFlexibility.flex', { context: String(flexibility) }),
    selected: flexibility === selectedFlexibility,
  }));

  return (
    <React.Fragment>
      <div
        sx={{
          borderBottomWidth: 'outlinedStrokeWeight',
          borderBottomStyle: 'solid',
          borderBottomColor: 'strokeLightneutral',
          padding: 's',
          display: ['none', 'block'],
        }}
      >
        <Label variant="mediumbold">{t('searchUi.flexibilityInput.selectorTitle')}</Label>
      </div>
      <div
        sx={{
          padding: [null, 'l'],
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(2, 1fr)',
          columnGap: 'xs',
          rowGap: 'xs',
        }}
      >
        {options.map((option) => (
          <TriggerButton
            key={option.label}
            data-id="flexibility-option"
            onTrigger={() => {
              setSelectedFlexibility(option.value);
              onChange();
            }}
            sx={{
              width: '100%',
              height: '100%',
              paddingY: '2xs',
              textAlign: 'center',
              color: 'textDefault',
              borderRadius: '8',
              borderStyle: 'solid',
              borderWidth: 'outlinedStrokeWeight',
              borderColor: 'strokeDarkneutral',

              ...(selectedFlexibility === option.value && {
                backgroundColor: 'backgroundBlack',
                borderColor: 'backgroundBlack',
                color: 'textLight',
              }),
              '&:hover, &:focus': {
                ...(selectedFlexibility !== option.value && {
                  backgroundColor: 'backgroundPrimarylight',
                  borderColor: 'backgroundPrimarylight',
                }),
              },
            }}
          >
            <Label variant="medium">{option.label}</Label>
          </TriggerButton>
        ))}
      </div>
    </React.Fragment>
  );
};
