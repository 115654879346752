import { unique } from '@Core/helpers/array';
import { YearMonthPrices } from '@Stores/SearchAvailabilityStore';

const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const getMonthCalendar = (
  calendar: YearMonthPrices,
): Record<string, Record<string, boolean>> => {
  const years = unique(Object.keys(calendar).map((yearMonth: string) => yearMonth.split('-')[0]));

  return years.reduce<Record<string, Record<string, boolean>>>((acc, year) => {
    months.forEach((mm) => {
      const yearMonth = `${year}-${mm}`;
      if (!acc[year]) {
        acc[year] = {};
      }
      acc[year][yearMonth] = calendar[yearMonth] && calendar[yearMonth].length !== 0;
    });

    return acc;
  }, {});
};
